<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="contractForm"
               label-width="140px"
               :rules="rules" ref="contractForm" class="add-form">
        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="contractForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="Gender">
          <el-select v-model="contractForm.Gender" disabled placeholder="请选择性别" class="w-all">
            <el-option
                v-for="item in sexList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="身份证/通行证" prop="IDNo">
          <el-input v-model="contractForm.IDNo" disabled placeholder="请填写身份证/通行证"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="InternMobile">
          <el-input v-model="contractForm.InternMobile" placeholder="请填写手机"></el-input>
        </el-form-item>
        <el-form-item label="个人邮箱" prop="InternEmail">
          <el-input v-model="contractForm.InternEmail" placeholder="请填写邮箱"></el-input>
        </el-form-item>
        <el-form-item label="常住地址" prop="InternAddress">
          <el-input v-model="contractForm.InternAddress" placeholder="请填写常住地址"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人姓名" prop="InternEmergencyContact">
          <el-input v-model="contractForm.InternEmergencyContact" placeholder="请填写紧急联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="与本人关系" prop="InternRelationship">
          <el-input v-model="contractForm.InternRelationship" placeholder="请填写与本人关系"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人电话" prop="InternEmergencyMobile">
          <el-input v-model="contractForm.InternEmergencyMobile" placeholder="请填写紧急联系人电话"></el-input>
        </el-form-item>

        <el-form-item label="法定送达地址" prop="InternMailAddress">
          <el-input v-model="contractForm.InternMailAddress" placeholder="请填写法定送达地址"></el-input>
        </el-form-item>
        <el-form-item label="法定送达地址邮编" prop="InternMailCode">
          <el-input type="number" v-model="contractForm.InternMailCode" placeholder="请填写法定送达地址邮编"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gangweimingcheng">
          <el-input v-model="contractForm.gangweimingcheng" placeholder="请填写岗位名称"></el-input>
        </el-form-item>
        <el-form-item label="兼职时间" prop="ConsultantDate" >
          <el-date-picker class="w-all"
                          v-model="contractForm.ConsultantDate"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="兼职开始日期"
                          end-placeholder="兼职结束日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label="兼职周期" prop="ConsultantPeriod">
          <el-input v-model="contractForm.ConsultantPeriod" placeholder="请填写兼职周期" type="number">
            <template slot="append">个月</template>
          </el-input>
        </el-form-item>
        <el-form-item label="工作方式" prop="ConsultantWorkType">
          <el-select v-model="contractForm.ConsultantWorkType" placeholder="请选择兼职工作方式" class="w-all">
            <el-option
                v-for="item in  wayOfWorking"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作模式" prop="gongzuomoshi">
          <el-select v-model="contractForm.gongzuomoshi" placeholder="请选择工作模式" class="w-all">
            <el-option
                v-for="item in workingMode"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="兼职薪酬" prop="jianzhixinchou">
          <el-input v-model="contractForm.jianzhixinchou" placeholder="请填写兼职薪酬" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item label="入职日期" prop="daoqishijian">
          <el-date-picker  align="center"
              class="w-all"
              v-model="contractForm.daoqishijian"
              type="date"
              placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('contractForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub flex align-center justify-between flex-direction preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0"  style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>

      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载兼职协议
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传兼职协议反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link  :href="upload_file" target="_blank" type="primary">
          下载兼职协议反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {QAHOST, upFile} from "@/config";
import {
  downLoadOfferWord,
  protocolShow,
  replyProtocolFile,
  staffPartTimeProtocol
} from "@/api/entry";
import {getPageName, zhDate} from "@/utils";
import {getOfferHtml} from "@/api/common";
import {sexList, wayOfWorking, workingMode} from "@/utils/const";

export default {
  name: "OrgList",
  data() {
    return {
      id: '',
      wayOfWorking:wayOfWorking,
      workingMode:workingMode,
      sexList:sexList,
      contractForm: {
        xingming:'',
        Gender:'',
        IDNo:'',
        InternMobile:'',
        InternEmail:'',
        InternAddress:'',
        InternEmergencyContact:'',
        InternRelationship:'',
        InternEmergencyMobile:'',
        InternMailAddress:'',
        InternMailCode:'',
        gangweimingcheng:'',
        Position:'',
        ConsultantDate:'',
        ConsultantPeriod:'',
        ConsultantWorkType:'',
        gongzuomoshi:'',
        jianzhixinchou:'',

        daoqishijian:'',

      },
      rules: {
        xingming: rules.mustInput,
        Gender: rules.mustSelect,
        IDNo: rules.mustInput,
        InternMobile: rules.mustInput,
        InternEmail: rules.mustInput,
        InternAddress: rules.mustInput,
        InternEmergencyContact: rules.mustInput,
        InternRelationship: rules.mustInput,
        InternEmergencyMobile: rules.mustInput,
        InternMailAddress: rules.mustInput,
        InternMailCode: rules.mustInput,
        gangweimingcheng: rules.mustInput,
        Position: rules.mustInput,
        ConsultantDate: rules.mustArray,
        ConsultantPeriod: rules.mustInput,
        ConsultantWorkType: rules.mustSelect,
        gongzuomoshi:rules.mustSelect,
        jianzhixinchou:rules.mustInput,
        daoqishijian: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/expertList', "兼职协议", true);
    this.id = Number(this.$route.params.id)
    this.protocolShow()
  },
  components: {},
  methods: {
    async protocolShow() {
      const res = await protocolShow(this.id, 3)
      this.sys_offer_file = res.data.sys_offer_file
      this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
      this.contractForm = {
        xingming: res.data.xingming,
        Gender:res.data.Gender?Number(res.data.Gender):'',
        IDNo:res.data.IDNo,
        InternMobile:res.data.InternMobile,
        InternEmail:res.data.InternEmail,
        InternAddress:res.data.InternAddress,
        InternEmergencyContact:res.data.InternEmergencyContact,
        InternRelationship:res.data.InternRelationship,
        InternEmergencyMobile:res.data.InternEmergencyMobile,
        InternMailAddress:res.data.InternMailAddress,
        InternMailCode:res.data.InternMailCode,
        gangweimingcheng:res.data.gangweimingcheng,
        Position:res.data.Position,
        ConsultantDate:res.data.ConsultantJoinDate?[res.data.ConsultantJoinDate,res.data.ConsultantResignDate]:[],
        ConsultantPeriod:res.data.ConsultantPeriod,
        ConsultantWorkType:res.data.ConsultantWorkType?Number(res.data.ConsultantWorkType):'',
        gongzuomoshi:res.data.gongzuomoshi?Number(res.data.gongzuomoshi):'',
        jianzhixinchou:res.data.jianzhixinchou,
        daoqishijian:res.data.daoqishijian?new Date(res.data.daoqishijian):'',

      }
      this.$nextTick(()=>{
        this.$refs.contractForm.clearValidate()
      })
      if(res.data.sys_offer_file) {
        const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
        this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
        this.$refs.iframe.contentWindow.location.replace(this.iframe)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await staffPartTimeProtocol(
        this.id,
          this.contractForm.xingming,
          this.contractForm.Gender,
          this.contractForm.IDNo,
          this.contractForm.InternMobile,
          this.contractForm.InternEmail,
          this.contractForm.InternAddress,
          this.contractForm.InternEmergencyContact,
          this.contractForm.InternRelationship,
          this.contractForm.InternEmergencyMobile,
          this.contractForm.InternMailAddress,
          this.contractForm.InternMailCode,
          this.contractForm.gangweimingcheng,
          this.contractForm.Position,
          zhDate(new Date(this.contractForm.ConsultantDate[0])),
          zhDate(new Date(this.contractForm.ConsultantDate[1])),
          this.contractForm.ConsultantPeriod,
          this.contractForm.ConsultantWorkType,
        this.contractForm.gongzuomoshi,
        this.contractForm.jianzhixinchou,
          zhDate(new Date(this.contractForm.daoqishijian)),



      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.protocolShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.contractForm.xingming + '兼职协议.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await replyProtocolFile(this.id, 3, url,'')
      this.$message.success('上传成功')
      await this.protocolShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
